@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

.carousel {
  box-shadow: 3px 3px 20px 8px #080808de;
  .slide {
    .legend {
      height: 100%;
      width: 100% !important;
      display: flex;
      flex-direction: column;
      left: 0 !important;
      bottom: 0 !important;
      opacity: 1 !important;
      color: #000 !important;
      padding: 0px !important;
      margin-left: 0 !important;
      font-size: 12px !important;
      text-align: left !important;
      background: none !important ;
      position: absolute !important;
      border-radius: 5px !important;
      transition: all 0.5s ease-in-out;
      z-index: 999;

      .close-desc {
        width: 100%;
        height: 70%;
        z-index: 999;
      }

      .desc-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.486);
        backdrop-filter: blur(5px);
        box-shadow: 1px -5px 11px 0px #00000020;
        padding: 10px !important;
        height: 30%;
        border-radius: 10px;
        z-index: 999;

        span {
          font-family: "Comfortaa", cursive;
          width: 100%;
          text-align: center;
          font-size: 15px;
          margin-bottom: 10px;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 8px 20px;
          width: 40%;
          text-align: center;
          background-color: #000;

          span {
            height: min-content;
            margin-bottom: 0;
            color: #fff;
            width: max-content;
            font-size: 17px;
            font-family: "Comfortaa", cursive;
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
    }

    img {
      border-radius: 10px;
    }
  }
}

.carousel.carousel-slider .control-arrow {
  width: 30% !important;
}
.carousel.carousel-slider .control-arrow:hover {
  opacity: 0 !important;
}

.myprogress {
  z-index: 10;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px 3px;
  display: flex;
  flex-wrap: nowrap;

  .prog-child {
    border-radius: 50px;
    overflow: hidden;
    height: 5px;
    width: 100%;
    background-color: rgb(105, 105, 105);
    margin: 0 2px;
    position: relative;

    .child-fill {
      position: absolute;
      height: 100%;
      background: #fff;
      top: 0;
      left: 0;
    }
    .child-fill.active {
      animation-name: fillprogress;
      animation-duration: 5s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      width: 0;
    }

    @keyframes fillprogress {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
  }
}
