@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
.landing-area {
    position: relative;
    background-image: linear-gradient(0deg, #a02b00, orangered);
    height: 100vh;
    display: flex;

    .container {
        justify-content: center;
        align-items: center;

        img {
            position: relative;
            width: auto;
            height: 50%;
        }

        p {
            font-family: 'Montserrat', sans-serif;
            position: relative;
            color: #313131;
            font-size: 5rem;
            float: left;
            text-align: right;
            font-weight: bold;
            text-shadow: 0px 8px 10px #a02b00;
        }

        @media screen and (max-width: 600px) {
            img {
                top: 99px;
                height: auto;
                width: 100%;
                position: relative;
            }

            p {
                font-size: 2rem;
                text-align: center;
                justify-self: center;
                width: 100%;
            }
        }
    }
}