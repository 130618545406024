
//================================================
// Blackberrys Two

#comp-div {
    width: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 241, 241);
}

.pop-up {
    position: absolute;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    width: 100%;
    height: max-content;
    min-height: 0px;

    button {
        border-radius: 0!important;
        background-color: #DB4B29;
        color: white;
        font-family: 'Montserrat-Light';
        font-size: 12px;
        border: none;
    }

    button:focus, button:hover, button:active {
        border-radius: 0!important;
        background-color: #DB4B29;
        color: white;
        border: none;
        box-shadow: 0 0 0 0;
    }

    .product-desc {
        background-color: #fff;
        padding: 0.5rem;
        border: 10px solid #301c08;
        
        .bb-logo-wrapper {
            width: 100%;
            background-color: #EFDABF;
            text-align: -webkit-center;
            padding: 0.5rem 0;
            img {
                width: 150px;
            }
        }

        .specs {
            margin-top: 1rem;
        }
        .addToCart, .specs {
            width: 100%;
            text-align: center;

            .cost, .colors {
                width: 100%;
                text-align: center;
                font-family: 'Montserrat-SemiBold';
            }

            .cost {
                font-size: 12px;
            }
            .colors {
                font-size: 13px;
            }
        }

        .quantity, .sizes {
            width: 100%;
            text-align: left;
            padding: 0.5rem 0;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;

            input[type="radio"] {
                margin-right: 3px;
            }

            input[type="number"] {
                border: 1px solid #301c08;
                padding: 0 10px;
                width: 50px;
                font-family: 'Montserrat-SemiBold', sans-serif;
                font-size: 13px;
            }

            #decrement {
                width: 20px;
                color: #DB4B29;
            }
            #increment {
                width: 20px;
                color: rgb(54, 168, 54)
            }

            label {
                margin-right: 1rem;
                font-size: 13px;
                font-family: Montserrat-SemiBold, sans-serif;
            }

            span {
                font-size: 13px;
                font-family: Montserrat-Medium, sans-serif;
                font-weight: bold;
                margin-right: 1rem;
            }
    
            svg {
                margin: 0 0.5rem;
            }

            .size {
                cursor: pointer;
                width: 30px;
                height: 30px;
                border: 1px solid #301c08;
                padding: 5px;
                margin: 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .size.active {
                border: 1px solid #DB4B29;
                color: white;
                background-color: #DB4B29;
            }
        }

    }

    .btn-row {
        width: 100%;

        button {
            position: absolute;
            top: -3rem;
            right: 1rem;        
        }
    }
}

#component-div {
    width: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212121;
}

.tte {
    position: relative;
    width: 280px;
    height: 498px;
    overflow: hidden;
    background-image: linear-gradient(0deg, #301c08, #683d12 );
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-image: linear-gradient(180deg, #301c08, #683d12 );
        border: 3px solid #fff;
    }

    button:focus, button:active, button:hover {
        box-shadow: 0 0 0 0 #fff;
        border: 3px solid #fff;
    }

}

.carousel-parent-wrapper {
    position: relative;
    width: 280px;
    height: 498px;
    overflow: hidden;
    
    .desc {
        position: absolute;
        top: 1rem;
        left: .5rem;
         
        span {
            color: white;
            font-family: Montserrat-Light, sans-serif;
        }

        span:first-child {
            font-family: Montserrat-Bold, sans-serif;
            color: rgb(255, 241, 241);
        }
    }

    .carousel-inner {
        height: 100%;
    }

    .carousel-video-wrapper {
        height: 100%;
        .ad-video {
            height: 100%;
            video {
                height: 100%;
            }
        }
    }
}

.vis-area {
    position: relative;
    min-height: 480px;
    height: 480px;
    width: 270px;
    overflow: hidden;
}

.poster {
    .bb-images {
        position: absolute;
        top: 0;
        left: 0;
    }
}

#bblogo {
    position: absolute;
    left: 10px;
    top: 8px;
    width: 78px;
    z-index: 2;
}

#mid-bblogo {
    position: absolute;
    left: 50%;
    top: 8px;
    margin-left: -50px;
    width: 100px;
    z-index: 2;
}

.ctrl-option {
    text-align: right;

    img {
        position: absolute;
        z-index: 2;
        left: 15px;
        bottom: 15px;
        width: 25px;
    }
}

.first-sc {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #18181844;
    height: 100%;
    width: 100%;
    z-index: 1;
    
    img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    .text {
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        z-index: 3;
        font-family: 'Roboto', sans-serif;
        color: #fff;
        font-weight: 200;
        font-style: italic;
        text-align: center;
    }

    button {
        position: absolute;
        left: 50%;
        margin-left: -63.5px;
        bottom: 20px;
        width: 125px;
        z-index: 2;

        border-radius: 0;
        background-color: transparent;
        border: 2px solid white;
        font-family: Montserrat-Medium, sans-serif;
        font-size: 10px;
    }

    button:active, button:hover {
        border-radius: 0;
        background-color: transparent;
        border: 2px solid white;
    }
}

#cta-btn {
    
    position: absolute;
    left: 50%;
    top: 62%;
    height: 30px;
    margin-left: -55px;
    bottom: 20px;
    width: 110px;
    z-index: 2;

    padding: 5px;
    color: #fff;
    text-decoration: none;
    border-radius: 0;
    background-color: transparent;
    border: 2px solid white;
    font-family: 'Montserrat-Medium', sans-serif;
    font-size: 10px;
}

#cta-btn:active, #cta-btn:hover {
    border-radius: 0;
    background-color: transparent;
    border: 2px solid white;
    font-family: 'Montserrat-Medium', sans-serif;
    font-size: 12px;
}

.btn-bottom {
    position: absolute;
    bottom: 10px;
    height: min-content;
    padding: 0 15px;
    z-index: 0.5;

    .btn-secondary {
        background-color: #DB4B29;
        color: #fff;
        width: 120px;
        height: min-content;
        border-radius: 0;
        font-size: 12px;
        border: none;
    }

    .btn-secondary:hover,
    .btn-secondary:focus, 
    .btn-secondary:active {
        background-color: #DB4B29;
        color: #fff;
        box-shadow: 0 0 0 0;
        border: none;
    } 
}

.icons-bottom {
    position: sticky;
    bottom: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    height: 53px;
    max-width: inherit;
    padding: 0 15px;
    z-index: 0.5;

    img {
        justify-self: center;
        width: 49px!important;
        cursor: pointer;
    }

    img:hover {
        width: 52px!important;
    }
}

// Summer Collection 

.summer-vis-area {
    position: relative;
    min-height: 480px;
    height: 480px;
    width: 320px;
    overflow: hidden;
}

.summer-first-sc {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #18181844;
    height: 100%;
    width: 100%;
    z-index: 1;
    
    img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    .text {
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        z-index: 3;
        font-family: 'Roboto', sans-serif;
        color: #fff;
        font-weight: 200;
        font-style: italic;
        text-align: center;
    }

    button.btn-secondary {
        position: absolute;
        left: 50%;
        margin-left: -63.5px;
        bottom: 20px;
        width: 125px;
        z-index: 2;

        border: none;
        background: transparent;
        font-family: Montserrat-Medium, sans-serif;
        font-size: 10px;
    }

    button.btn-secondary:active, 
    button.btn-secondary:hover, 
    button.btn-secondary:focus {
        border: none;
        background: transparent;
        box-shadow: 0;
    }
}

.master-screen {
    span {
        font-size: 10px;
        position: absolute;
        color: #fff;
        line-height: 1;
        z-index: 2;
        font-family: Montserrat-Medium, sans-serif;
        font-size: 15px;
        left: 51%;
        margin-left: -63.5px;
        width: 125px;
    }

    .noon {
        top: 28px;
        cursor: pointer;
    }

    .pool {
        top: 188px;
        cursor: pointer;
    }

    .eve {
        top: 360px;
        cursor: pointer;
    }

    .msg {
        color: #cacaca;
        top: 435px;
        font-size: 10px;
    }
}

.looks-screen {
    .back-icon {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
        color: #fff;
        width: 40px;
        height: 40px;
    }

    .atc {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        z-index: 1;
        border: none;
        font-size: 12px;
        font-family: Montserrat-SemiBold;
    }

    .btn-secondary {
        background-color: #DB4B29;
        color: #fff;
        width: 120px;
        height: min-content;
        border-radius: 0;
    }

    .btn-secondary:hover,
    .btn-secondary:focus, 
    .btn-secondary:active {
        background-color: #DB4B29;
        color: #fff;
        box-shadow: 0;
    } 
}