:root {
    --insize: 25px;
    --outsize: 30px;
}

.hotspot {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    // top: var(--topPos);
    // left: var(--leftPos);

    svg {
        position: absolute;
    }
    .outer-circle {
        position: absolute;
        width: var(--outsize);
        height: var(--outsize);
        border-radius: 50%;
        border: 1px solid #fff;
        background-color: transparent;
        transition-duration: 0.3s;
        animation: outmove 2s infinite;
        z-index: 1;
    }

    @keyframes outmove {
        0% {
            width: var(--outsize);
            height: var(--outsize);
        }
        25%  {
            width: calc(var(--outsize)  + 5px);
            height: calc(var(--outsize)  + 5px);
        }
        50% {
            width: calc(var(--outsize)  + 2px);
            height: calc(var(--outsize)  + 2px);
        }
        75%  {
            width: calc(var(--outsize)  + 6px);
            height: calc(var(--outsize)  + 6px);
        }
        100% {
            width: var(--outsize);
            height: var(--outsize);
        }
    }

    .inner-circle {
        position: absolute;
        width: var(--insize);
        height: var(--insize);
        border-radius: 50%;
        background-color:#fff;
        transition-duration: 0.4s;
        z-index: 2;
    }
    .inner-circle:active {
        box-shadow: 0 0 10px 4px #cacaca;
    }
}

.hotspot:hover, .hotspot:active {
    opacity: 1;
}

.hot-collapse, .collapsing {
    position: absolute!important;
    z-index: 1;
    width: 100px;
    // top: calc(var(--topPos) + 15px);
    // left: calc(var(--leftPos) - 110px);
}


.card { 
    border-radius: 0!important;
    .card-body {
        flex: 1 auto!important;
        min-height: 1px!important;
        padding: 0.5rem!important;

        .pop-title {
            font-family: Montserrat-Bold;
        }

        .pop-desc {
            font-size: 14px;
            color: #fff;
            font-family: Montserrat-Medium;
            margin: 0!important;
            padding: 0!important;
        }
    }
    .card-text {
        color: #fff;
    }
}

@media only screen and (max-width: 600px) {
    .card { 
        border-radius: 0!important;
        width: 200px;
        
        .card-body {
            flex: 1 auto!important;
            min-height: 1px!important;
            padding: 0.5rem!important;
            background-color: black!important;
    
            .pop-title {
                color: #fff;
                font-size: 12px!important;
                font-family: Montserrat-SemiBold;
            }
    
            .pop-desc {
                font-size: 12px;
                color: #fff;
                font-family: Montserrat-Light;
                margin: 0!important;
                padding: 0!important;
            }
        }
        .card-text {
            color: #fff;
        }
    }
}

