.vega {
  .frame-1 {
    position: relative;
    width: 320px;
    height: 480px;

    .men,
    .women {
      position: absolute;
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .gender {
        background-color: #fff;
        width: 110px;
        height: 34px;
        font-family: "Futura-Regular";
      }
    }
    .men {
      top: 0;
      left: 0;
    }
    .women {
      top: 50%;
      left: 0;
    }
  }
}

.men-component {
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    width: 50px !important;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #141414;
    opacity: 1 !important;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #141414;
    opacity: 1 !important;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow:hover {
    background-color: transparent !important;
    opacity: 1 !important;
  }

  .vega-cta {
    position: relative;
    
    .vega-back {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
      left: 15px;
    }
    .men-cta-btn {
      background-color: #414332;
      text-align: center;
      text-decoration: none;
      width: 100px;
      height: 24px;
      color: #fff;
      position: absolute;
      top: 433px;
      left: 176px;
      font-family: "Futura-Medium";
    }
  }
}

.women-component {
  overflow: hidden;
  position: relative;

  .w-prod1,
  .w-prod2,
  .w-prod3 {
    position: absolute;
  }
  .w-prod1 {
    bottom: 0;
    right: 0;
    width: 108px;
    height: 158px;
  }
  .w-prod2 {
    top: 76px;
    right: 0;
    width: 130px;
    height: 127px;
  }
  .w-prod3 {
    top: 190px;
    left: 0;
    width: 100px;
    height: 173px;
  }
  .vega-cta {
    position: relative;

    .vega-back {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
      left: 15px;
    }
    .women-cta-btn {
      background-color: #ed52a4;
      text-align: center;
      text-decoration: none;
      width: 100px;
      height: 24px;
      color: #fff;
      position: absolute;
      top: 130px;
      left: 50%;
      margin-left: -50px;
      font-family: "Futura-Medium";
    }
  }
}
