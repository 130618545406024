.smoke {
  animation-name: moveright;
  animation-duration: 7s;
  opacity: 0.2;
  transform: rotate(90deg);
  left: 0;
  top: 0;
  animation-iteration-count: infinite;
}

@keyframes moveright {
  0% {
    left: 0;
  }
  50% {
    left: 30%;
  }
  100% {
    left: 0;
  }
}

.uppertext {
  position: absolute;
  left: 10%;
  top: 12%;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  .u-one {
    font-family: Montserrat-ExtraBold;
    font-size: 30px;
    color: #ec1b21;
    line-height: 0.5;
  }
  .u-two {
    font-family: Montserrat-Bold;
    font-size: 20px;
    color: #000000;
  }
}

.lowertext {
  position: absolute;
  width: 100%;
  left: 0%;
  bottom: 18%;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .u-one {
    font-family: Montserrat-ExtraBold;
    font-size: 30px;
    color: #ec1b21;
    line-height: 0.5;
  }
  .u-two {
    font-family: Montserrat-Bold;
    font-size: 20px;
    color: #000000;
  }
}

.button-one {
  position: absolute;
  bottom: 10%;
  justify-self: center;
  background-color: #ec1b21;
  color: #ffffff;
  font-family: Montserrat-SemiBold;
  z-index: 40;
  padding: 7px 20px;
}

.frameTwo {
  position: relative;
  width: 100%;
  height: 100%;

  .swipearea {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    z-index: 40;
  }

  .locations {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 22.5%;
    left: 0;
    z-index: 10;

    button {
      font-family: Montserrat-SemiBold;
      font-size: 15px;
    }
  }
  .aqi {
    left: 0;
    top: 39%;
    z-index: 10;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .two {
      font-family: Montserrat-ExtraBold;
      font-size: 32px;
      text-shadow: 1px 1px 0px #00000050;
    }
  }

  .pm {
    left: 0;
    top: 59%;
    z-index: 10;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .pm25,
    .pm10 {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .pm25 {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .pm25_h {
        font-family: Montserrat-ExtraBold;
        color: #939598;
        font-size: 17px;
      }
      .pm25_v {
        font-family: Montserrat-ExtraBold;
        color: #000;
        font-size: 15px;
      }
    }
    .pm10 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .pm10_h {
        font-family: Montserrat-ExtraBold;
        color: #939598;
        font-size: 17px;
      }
      .pm10_v {
        font-family: Montserrat-ExtraBold;
        color: #000;
        font-size: 15px;
      }
    }
  }

  .icon-up {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 10%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;

    svg {
      animation-name: float;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      color: #000;
    }

    span {
      color: #000;
      font-size: 15px;
    }
  }
}

@keyframes float {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(-20px);
  }
  100% {
    transform: translatex(0px);
  }
}

.frame-three {
  display: flex;
  justify-content: center;

  .button-two {
    position: absolute;
    padding: 7px 22px;
    top: 23%;
    background-color: #fff;
    border-radius: 50px;
    z-index: 20;
    display: flex;
    align-items: center;

    a {
      color: #000;
      font-size: 11px;
      font-family: Montserrat-SemiBold;
    }
  }
}
