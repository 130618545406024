.page {
  width: 100%;
  padding: 2rem;
  height: 100vh;
  display: flex;
  align-items: center;
  background: #313131;

  .frame {
    width: 70%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
  }
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 9%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .buttons {
    padding: 0.5vh 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    .ctrl-icon {
      margin: 0 0.7vw;
      svg.playing {
        width: 2.2vw;
        height: 2.2vw;
      }

      svg.muted {
        width: 2vw;
        height: 2vw;
      }

      span.time {
        color: #ffffff;
        font-weight: bold;
        font-size: 1.1vw;
      }
    }
  }
}

.c-wrapper {
  bottom: 10%;
  background: #ff0d0d;
  width: 100%;
  position: absolute;

  .offer-text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px;
    font-family: Montserrat-SemiBold;
    color: #ffffff;
    background-color: #fb0313;
    font-size: 1vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel.carousel-slider .control-arrow {
    font-size: 0 !important;
  }

  .slider-wrapper {
    ul {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      li {
        margin: 0;

        .offer-info {
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            text-decoration: none;
            .offer {
              color: #ffffff;
              font-size: 1vw;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .page {
    padding: 0rem;
    height: 100vh;

    .frame {
      width: 100%;
    }
  }

  .controls {
    .buttons {
      .ctrl-icon {
        width: 4vw;
        height: 4vw;
      }
    }
  }

  .c-wrapper {
    .offer-text {
      font-size: 2vw;
    }
    .slider-wrapper {
      ul {
        li {
          .offer-info {
            a {
              text-decoration: none;
              .offer {
                font-size: 2vw;
              }
            }
          }
        }
      }
    }
  }
}

.open-prod {
  position: absolute;
  right: 0;
  top: 10px;
  background-color: #fb0313;
  border-radius: 50px 0 0 50px;
  padding: 3px 10px;

  .show-prod {
    width: 2vw;
  }
}

.products {
  width: 20%;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  padding: 10px;
  background-color: #ffffff7c;
  box-shadow: #000000a1 -4px 0px 20px 2px;

  #icon {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px; 
    img {
      width: 15px;
      height: 15px;
    }
  }

  #text {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    img {
      width: 100%;
    }
  }

  .carousel-root {
    height: 100%;

    .carousel.carousel-slider {
      height: 100%;

      .slider-wrapper {
        height: 100% !important;
      }
    }
  }

  .card {
    margin-bottom: 5px;
    border-radius: 10px !important;
    box-shadow: #000000a1 0px 0px 3px 0px;

    .card-head {
      text-align: center !important;
      font-size: 1vw;
      font-weight: bold;
      border-bottom: dotted red 1px;
    }

    .card-body {
      text-align: center;
    }
    .card-title {
      margin: 0 !important;
      font-size: 1vw !important;
    }

    .card-subtitle {
      font-size: 1vw !important;
      color: #fb0313 !important;
      font-weight: bold;
    }
  }

  img {
    width: 100%;
  }
}
