.back-arrow {
    transform: rotate(180deg);
    position: absolute;
    bottom: 0;
    left: 0;
}

.btn-knowmore {
    background-color: white;
    color: #313131;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none!important;
}