.shake-app-wrapper {
	display: flex;
	justify-content: center;
	background-color: #212121;
}

#shake-id {
	position: relative;
	height: 480px;
	width: 320px;
	overflow: hidden;

	.wc-options {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.shake-icon {
	position: absolute;
	left: 50%;
	top: 40%;
	animation: shakeMe 2s infinite;
	transition-duration: 0.3s;
}

@keyframes shakeMe {
	0% {
		transform: rotate(90deg);	
	}
	10%  {	
		transform: rotate(0deg);
	}
	20%  {	
		transform: rotate(90deg);
	}
	30%  {	
		transform: rotate(0deg);
	}
	40%  {	
		transform: rotate(90deg);
	}
	50%  {	
		transform: rotate(0deg);
	}
	60%  {	
		transform: rotate(90deg);
	}
	70% {
		transform: rotate(0deg);
	}
	80%  {
		transform: rotate(90deg);
	}
	90% {
		transform: rotate(0deg);	
	}
	100% {
		transform: rotate(90deg);
	}
}

#pool {
	position: absolute;
	top: 25%;
	left: 48%;
	z-index: 1;
	width: 85px;
	transform: rotate(-25deg);
	height: 135px;
}


#noon {
	position: absolute;
	top: 45%;
	left: 28%;
	z-index: 1;
	width: 85px;
	transform: rotate(-60deg);
	height: 135px;
}

#evening {
	position: absolute;
	top: 25%;
	left: -15px;
	z-index: 1;
	width: 85px;
	transform: rotate(40deg);
	height: 135px;
}