.navbar {
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    img {
        width: 200px;
    }
}

.navbar-light {
    .navbar-toggler {
        border: none;
    }
} 

.nav-item {
    margin: 1rem 1rem;
    a {
        font-family: 'Mulish', sans-serif;
        font-weight: bolder;
        color: orange!important;
        padding: .5rem .5rem;
        display: inline;
    }
}

.logout {
    background-color: rgb(224, 46, 46)!important;
    color: #313131!important;
    border-radius: 10px;
    font-family: Montserrat-Bold;
    transition-duration: 0.5s;
}

.logout:hover {
    background-color: rgb(224, 46, 46)!important;
    box-shadow: 0 0 0 3px rgb(255, 79, 79) ;
    color: #fff!important;
    border-radius: 10px;
}
